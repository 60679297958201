<template>
  <div class="sideBar" :style="'top:' + top + 'px'">
    <div :key="index" v-for="(item, index) in paragraphMeta">
      <div class="handle-container">
        <div class="icon-wrapper">
          <div
            v-if="item.visible && (item.labelName || item.labelColor)"
            class="icon label-icon"
            :style="
              'color:' +
                item.labelColor +
                ';' +
                'top:' +
                (item.paraCoords.top - 107) +
                'px;'
            "
            :class="[
              'positionAbsolute',
              { 'active-label': item.labelColor },
              { 'hide-icon': !item.visible },
              { 'on-popover-open': labelPopoverConfig.show },
              { 'label-on-hover': !item.labelColor }
            ]"
            @click="showLabelPopover($event, index)"
          >
            <TagIcon
              classname="h-4 w-4 focus:outline-none relative -top-1"
              v-tooltip.right="item.labelName || 'Label'"
              v-if="
                (labelPopoverConfig.show && currentIndex === index) ||
                  item.labelColor
              "
            />
            <div
              v-else
              v-tooltip.right="item.labelName || 'Label'"
              :class="[
                'newTagOuter',
                labelPopoverConfig.show
                  ? ''
                  : 'animate__animated animate__heartBeat animate__2'
              ]"
            >
              <div class="newTagInner" />
            </div>
          </div>
          <div
            v-else-if="item.visible && currentPara === index"
            class="icon label-icon"
            :style="
              'color:' +
                item.labelColor +
                ';' +
                'top:' +
                (item.paraCoords.top - 107) +
                'px;'
            "
            :class="[
              'positionAbsolute',
              { 'active-label': item.labelColor },
              { 'hide-icon': !item.visible },
              { 'on-popover-open': labelPopoverConfig.show },
              { 'label-on-hover': !item.labelColor }
            ]"
            @click="showLabelPopover($event, index)"
          >
            <TagIcon
              classname="h-4 w-4 focus:outline-none relative -top-1"
              v-tooltip.right="item.labelName || 'Label'"
              v-if="
                (labelPopoverConfig.show && currentIndex === index) ||
                  item.labelColor
              "
            />
            <div
              v-else
              v-tooltip.right="item.labelName || 'Label'"
              :class="[
                'newTagOuter',
                labelPopoverConfig.show
                  ? ''
                  : 'animate__animated animate__heartBeat animate__2'
              ]"
            >
              <div class="newTagInner" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddLabelPopover
      ref="label"
      :labelColor="labelColor"
      :labelName="labelName"
      :paraPos="paraPos"
    />
  </div>
</template>

<script>
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
export default {
  data() {
    return {
      top: 0,
      labelName: '',
      labelColor: '',
      paraPos: 0,
      currentIndex: 0
    }
  },
  components: {
    AddLabelPopover: () =>
      import(/* webpackChunkName: "AddLabelPopover" */ './AddLabelPopover'),
    TagIcon: () =>
      import(
        /* webpackChunkName: "TagIcon" */ '@/components/base/icons/Tag.vue'
      )
  },
  computed: {
    paragraphMeta() {
      // console.log('meta', store.state.doc.paragraphMeta)
      return store.state.doc.paragraphMeta
    },
    currentPara() {
      return store.state.doc.currentPara
    },
    labelPopoverConfig() {
      return store.state.editor.labelPopoverConfig
    }
  },
  methods: {
    showLabelPopover(e, i) {
      store.state.editor.labelPopoverConfig.show = true
      store.state.editor.labelPopoverConfig.isPositionFixed = true
      store.state.editor.labelPopoverConfig.style = {
        left: e.clientX + 'px',
        top: e.clientY + 11 + 'px'
      }
      store.state.editor.labelPopoverConfig.closeOnClickOutside = true
      this.labelColor = store.state.doc.paragraphMeta[i].labelColor
      this.paraPos = store.state.doc.paragraphMeta[i].paraPos
      this.labelName = store.state.doc.paragraphMeta[i].labelName
      if (this.labelColor === '') {
        myProseEditor.setParagraphMeta(this.paraPos, {
          labelName: this.labelName,
          labelColor: '#f44236'
        })
      }
      this.currentIndex = i
      this.$refs.label.localLabelName = this.labelName
      this.$refs.label.resetFocus()
    }
  },
  watch: {
    paragraphMeta(val) {
      const editorContainer = document.getElementById('editorContainer')
      this.top = editorContainer.scrollTop
      if (!val[this.currentIndex]) {
        return
      }
      this.labelColor = val[this.currentIndex].labelColor
      this.paraPos = val[this.currentIndex].paraPos
      this.labelName = val[this.currentIndex].labelName
    }
  }
}
</script>
<style lang="scss" scoped>
.newTagOuter {
  position: relative;
  top: -4px;
  box-shadow: 0px 0px 6px rgba(251, 86, 7, 0.1);
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(226, 70, 44, 0.1);
}

.newTagInner {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background: #e2462c;
}

.sideBar {
  position: absolute;
  left: -24px;
  top: -4px;
}

.positionAbsolute {
  position: absolute;
  cursor: pointer;
  right: 0;
  line-height: 1;
}

.bold {
  font-weight: 900;
}

.handle-container {
  display: flex;

  .icon-wrapper {
    display: flex;
    align-items: center;
    height: max-content;
    padding: 8px;
  }

  .icon {
    padding: 0;
    color: #a5bfc9;
    cursor: pointer;

    i {
      transition: opacity 0.5s ease;
    }
  }

  .hide-icon {
    i {
      opacity: 0;
    }
  }

  .on-popover-open {
    i {
      opacity: 1;
    }
  }

  .active-label {
    i {
      opacity: 1 !important;
      transition: opacity 0.5s ease;
    }
  }

  .add-icon {
    :hover {
      color: #5a29a3;
    }
  }

  .label-on-hover {
    :hover {
      color: #5a29a3;
    }
  }

  .label-fade-on-hover {
    :hover {
      opacity: 0.6 !important;
    }
  }

  .active-icon {
    color: #5a29a3;
  }

  .drag-icon {
    display: flex !important;

    &:hover i {
      cursor: grab;
      color: #5a29a3;
    }
  }
}
</style>
