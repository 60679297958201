var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sideBar",style:('top:' + _vm.top + 'px')},[_vm._l((_vm.paragraphMeta),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"handle-container"},[_c('div',{staticClass:"icon-wrapper"},[(item.visible && (item.labelName || item.labelColor))?_c('div',{staticClass:"icon label-icon",class:[
            'positionAbsolute',
            { 'active-label': item.labelColor },
            { 'hide-icon': !item.visible },
            { 'on-popover-open': _vm.labelPopoverConfig.show },
            { 'label-on-hover': !item.labelColor }
          ],style:('color:' +
              item.labelColor +
              ';' +
              'top:' +
              (item.paraCoords.top - 107) +
              'px;'),on:{"click":function($event){return _vm.showLabelPopover($event, index)}}},[(
              (_vm.labelPopoverConfig.show && _vm.currentIndex === index) ||
                item.labelColor
            )?_c('TagIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(item.labelName || 'Label'),expression:"item.labelName || 'Label'",modifiers:{"right":true}}],attrs:{"classname":"h-4 w-4 focus:outline-none relative -top-1"}}):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(item.labelName || 'Label'),expression:"item.labelName || 'Label'",modifiers:{"right":true}}],class:[
              'newTagOuter',
              _vm.labelPopoverConfig.show
                ? ''
                : 'animate__animated animate__heartBeat animate__2'
            ]},[_c('div',{staticClass:"newTagInner"})])],1):(item.visible && _vm.currentPara === index)?_c('div',{staticClass:"icon label-icon",class:[
            'positionAbsolute',
            { 'active-label': item.labelColor },
            { 'hide-icon': !item.visible },
            { 'on-popover-open': _vm.labelPopoverConfig.show },
            { 'label-on-hover': !item.labelColor }
          ],style:('color:' +
              item.labelColor +
              ';' +
              'top:' +
              (item.paraCoords.top - 107) +
              'px;'),on:{"click":function($event){return _vm.showLabelPopover($event, index)}}},[(
              (_vm.labelPopoverConfig.show && _vm.currentIndex === index) ||
                item.labelColor
            )?_c('TagIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(item.labelName || 'Label'),expression:"item.labelName || 'Label'",modifiers:{"right":true}}],attrs:{"classname":"h-4 w-4 focus:outline-none relative -top-1"}}):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(item.labelName || 'Label'),expression:"item.labelName || 'Label'",modifiers:{"right":true}}],class:[
              'newTagOuter',
              _vm.labelPopoverConfig.show
                ? ''
                : 'animate__animated animate__heartBeat animate__2'
            ]},[_c('div',{staticClass:"newTagInner"})])],1):_vm._e()])])])}),_vm._v(" "),_c('AddLabelPopover',{ref:"label",attrs:{"labelColor":_vm.labelColor,"labelName":_vm.labelName,"paraPos":_vm.paraPos}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }